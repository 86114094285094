export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const numberPattern = /^\+?[0-9]+$/;
export const googlePlaceAPIKey = 'AIzaSyCsu9_b70IS1m92XjvaZw96NTNTD4fgHN4';
export const IPurl = `https://ipinfo.io`;
export const languageArray = [
  { prefix: 'en', name: 'English' },
  { prefix: 'es', name: 'Española' },
];
export const paginationArray = [10, 25, 50, 100];

export const localStorageKeys = ['vendorUserDetail', 'selected_language', 'selected_location_id', 'token'];


