import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginFormInputFields } from './auth.interface';
import { ResponseMessage } from 'src/app/shared/interfaces/req.res.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IPurl } from 'src/app/shared/model/const/common.const';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private http: HttpClient,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }


  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }




 
  forgotPassword(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('forget-password', data);
  }

  resetPassword(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('reset-password', data);
  }

  
  //#region Start Vendor admin api's
  getToken(): string {
    return localStorage.getItem('token') as string;
  }

  signin(data: LoginFormInputFields): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('vendoradmin/login', data);
  }

  getCountryName(): Observable<any> {
    const url = IPurl;
    return this.http.get(url);
  }

  signinViaSwitch(data: any, token: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('login-via-switch', data);
    // let header = new HttpHeaders().set('Authorization', `Bearer ${token}`)
    // return this.http.post<ResponseMessage>('login-via-switch', data, { headers: header });
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
