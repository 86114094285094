import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

// Check Before Login
export const authGuard: CanActivateFn = (route, state) => {
  if (!inject(AuthService).getToken()) {
    inject(Router).navigate(['/vendor/login'])
    return false;
  }
  return true;
};

// Check after Login
export const authGuardafterLogin: CanActivateFn = (route, state) => {
  if (inject(AuthService).getToken()) {
    if (route.routeConfig?.path == 'vendor' || route.routeConfig?.path == 'vendor/login') {
      inject(Router).navigate(['/dashboard'])
      return true;
    }
  }
  return true;
};
