import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LangType, ThemeType } from '../model/types/GlobalTypes';



// @Injectable({
//   providedIn: 'root',
// })
export class TokenInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const router = inject(Router);
    const language = 'selected_language';
    // const theme = 'selected_theme';
    const authToken = localStorage.getItem('token');
    const lang = localStorage.getItem(language) || environment.defaultLang;
    const offset = new Date().getTimezoneOffset().toString();
    let authReq: any;
    if (req.url.startsWith('http') || req.url.includes('assets')) {
      authReq = req.clone();
    } else {
      let newHeaders = req.headers
        .append('Authorization', `Bearer ${authToken}`)
        .append('Accept-Language', lang)
        .append('Timezone-Offset', offset);
      authReq = req.clone({
        headers: newHeaders,
        url: `${environment.apiUrl}${req.url}`,
      });
    }
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 422) {
          // const storedTheme = localStorage.getItem(theme) as ThemeType;
          const storedLanguage = localStorage.getItem(language) as LangType;
          localStorage.clear();
          // localStorage.setItem(theme, storedTheme);
          localStorage.setItem(language, storedLanguage);
          router.navigate(['/vendor/login']);
        }
        // Return the error to the calling service
        return throwError(() => error);
      })
    );
  }
}